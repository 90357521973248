import React, { useState } from 'react'
import Masonry from "react-masonry-css";
import { useInView } from "react-intersection-observer";
import "./porfoliogallery.css"; // For styling
import { CSSTransition } from "react-transition-group";

function PortfolioGallery({ pictures, portTitle }) {
    const [selectedPicture, setSelectedPicture] = useState(null);

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1,
      };
    
      return (
        <>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {pictures.map((picture) => (
            <PictureCard 
              key={picture.cloudinaryid} 
              picture={picture} 
              portTitle={portTitle}
              onClick={() => setSelectedPicture(picture)}
              />
          ))}
        </Masonry>

        {selectedPicture && (
          <ModalProfile
              picture={selectedPicture}
              onClose={() => setSelectedPicture(null)}
            />
          )}
        </>
      );
};
    
    const PictureCard = ({ picture, portTitle, onClick }) => {
      const { ref, inView } = useInView({ threshold: 0.1, triggerOnce: true });
    
      return (
        <div
          ref={ref}
          className={`picture-card ${inView ? "fade-in" : "hidden"}`}
          onClick={onClick}
        >
          <img
            src={picture.picurl}
            alt={"Photocutt " + portTitle}
            className="picture-image"

          />
        </div>
      );
    };

    
const ModalProfile = ({ picture, onClose }) => {
  return (
    <>
      <div className="modal-overlay-profile" onClick={onClose}>
         
            <div className="modal-profile-content">
              <img
                src={picture.picurl}
                alt="Full View"
                className="modal-image"
              />
              <button className="modal-close" onClick={onClose}>
                ✖
              </button>
            </div>
        </div>
        </>
      );
    };
export default PortfolioGallery