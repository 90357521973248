import React, { useEffect, useRef, useState } from 'react'
import TopNavigation from '../Components/TopNavigation';
import FooterHolder from '../Components/FooterHolder';
import { useSelector } from 'react-redux';
import imagePortfolio from "./../assets/albumcreate.png";
import imageAccess from "./../assets/error-view.png";
import * as CONSTANTS from "./../CONSTANTS";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

function PortfolioCreate() {
    const [processing, setProcessing]                             = useState(false);
    const {user}                                                  = useSelector((state) => state.auth);

    const [portfolioURL, setPortfolioURL]                         = useState("");

    const titleRef                                                = useRef();
    const biorRef                                                 = useRef();
    const visibilityRef                                           = useRef();

    const HandleCreatingPortfolio = async (e) => {
      e.preventDefault();

      try{
        setProcessing(true);

        const objectAlbum = {
            "title" : titleRef.current.value,
            "description" : biorRef.current.value,
            "visible" : visibilityRef.current.value,
            "userid" : user._id
          }

          const res = await axios.post(CONSTANTS.API_URL +"portfolios/", objectAlbum, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
    
            
            if(res.status === 203){
              toast.warning("Your have reached the prtfolio limit.");
            }else{
              toast.success("Your portfolio has been created.");
            }
            
            titleRef.current.value    = "";
            biorRef.current.value     = "";

            setPortfolioURL(res.data._id);
        setProcessing(false);
      }catch(err){
        console.log(err);
        toast.error("Something went wrong, please try again later.");
        setProcessing(false)
      }
    }
    
  return (
    <div className="stream-container">
        <TopNavigation />
        <div className="content-display">
            <div className="initial-title">
              Create Portfolio
            </div>
           <div className="content-main">
             
                {
                  user ? (
                    <div className="row">
                          <div className="col-md-6">
                            <div className="form-item form-pharm">
                              <form onSubmit={HandleCreatingPortfolio}>
                                  <div className="form-group">
                                      <div className="lbldesc">Portfolio Name*:</div>
                                      <input type="text" className="form-control wide100" ref={titleRef} required/>
                                  </div>

                                  <div className="form-group">
                                      <div className="lbldesc">Portfolio Bio:</div>
                                      <textarea className="form-control wide100" ref={biorRef} ></textarea>
                                  </div>
                                  
                                  <div className="form-group">
                                      <div className="lbldesc">Status*:</div>
                                      <select className="form-control wide100" ref={visibilityRef} >
                                          <option value={true}>Public (visible to any user)</option>
                                          <option value={false}>Private (Private to you)</option>
                                      </select>
                                  </div> 
                                  
                                  <div className="form-group">
                                      <button type="submit" className="btn btn-photocut" disabled={processing}>Create</button>                             
                                  </div>
                              </form>
                            </div>
                          </div>
                          <div className="col-md-6">
                             <div className="base-art">
                                  <img src={imagePortfolio} className="on-display img-responsive" alt="Portfolio Work" />
                              </div>
                          </div>
                      </div>
                  ) 
                  :
                  <div className="access-section text-center">
                      <img src={imageAccess} className="img-rep1" />  
                   </div>
                }
                  {
                      processing && (
                          <div className="img-source">
                          <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                          </div>
                      )
                  }

                  {
                      portfolioURL && (<Link to={"/single-portfolio/" + portfolioURL}>View Portfolio</Link>)
                  }
             </div>
          
        </div>
        <div className="below-bottom">
          <FooterHolder />
        </div>
    </div>
  )
}

export default PortfolioCreate