import React, { useEffect, useRef, useState } from 'react'
import TopNavigation from '../Components/TopNavigation'
import axios from "axios";
import { toast } from 'react-toastify';
import * as CONSTANTS from "./../CONSTANTS";
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import imageRestriction from "./../assets/restriction.png"
import { FaTrash, FaDownload, FaFileDownload } from 'react-icons/fa';
import JSZip from 'jszip';
import FooterHolder from '../Components/FooterHolder';
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import { Flex } from '@chakra-ui/react'

function AlbumSingle() {
    const [processing, setProcessing]                                = useState(false);
    const {user}                                                     = useSelector((state) => state.auth);
    const [currentAlbum, setCurrentAlbum]                            = useState();
    const [albumReceived, setAlbumReceived]                          = useState(0);
    const [pictureList, setPictureList]                              = useState([]);
    const [messageResp, setMessageResponse]                          = useState("");
    const [pageNum, setPageNum]                                      = useState(1);
    const [listCompleted, setListCompleted]                          = useState(false);
    const pageSize                                                   = 10;
    const [hasMore, setHasMore]                                      = useState(true);

    const navigate                                                   = useNavigate();

    const params                                                     = useParams();

    const imageDataRef                                               = useRef();
    const DownloadCodeRef                                            = useRef();
    const checkCodeRef                                               = useRef();

    const [model, setModel]                                          = useState(false);
    const [albDeleteConfirmationModel, setAlbumDeleteConfirmation]   = useState(false);
    const [tempingSrc, setTempingSrc]                                = useState('');

    const getImage = (imgSrc, event) => {
      
      if (event.target.classList.contains('img-special')) {
          setTempingSrc(imgSrc);
          setModel(true);
      }else {
        console.log("oooo");
      }  
    }

    useEffect(() => {     
      collectCurrentAlbum();
    }, []);

    useEffect(() => {
      if(albumReceived > 0){
        
        if(listCompleted == false ){
          if(hasMore){
            collectMyPictures();
          }
          window.addEventListener('scroll', handleScroll);
        }        
      }
    }, [albumReceived, hasMore]);

    useEffect(() => {
      if(listCompleted === true){
        
        window.removeEventListener('scroll', handleScroll);
        setListCompleted(false)
      }
    },[listCompleted])

    
    const collectCurrentAlbum = async () => {

      try{
  
        const res = await axios.get(CONSTANTS.API_URL +"albums/current/index/" + params.id);
 
        setCurrentAlbum(res.data);
        setAlbumReceived(true);
      }catch (err) {
          console.log(err);        
      }   
    }

    const collectMyPictures = async () => {
 
      try{

        const picCollect = {
          "pageNum" :pageNum,
          "pageSize" : pageSize
        }
     
          const responseCurrent = await axios.put(CONSTANTS.API_URL+"pictures/collect/list/" + currentAlbum.albumPicCode, picCollect);
         
          if(responseCurrent.data.length == 0){
            setListCompleted(prevopt => true);
          } else {
            setPictureList(prevList => [...prevList, ...responseCurrent.data]);
            setPageNum(prevPage => prevPage + 1);
          }
          setHasMore(false);
      }catch(err){
        console.log(err);
      }
    }

    const handleScroll = () => {
    
      if(listCompleted == false ){
        const ihscrollTop = window.innerHeight + Math.round(document.documentElement.scrollTop + 10);
    
          const documentHeight = Math.round(document.documentElement.offsetHeight);
         
          if(ihscrollTop >= documentHeight && listCompleted == false) {
           
            setHasMore(true);
          }
      }
    };

    const reviewHassMore = () => {
      /*
       console.log("reviewHassMore")
        console.log(hasMore);
        console.log("Page Number")
        console.log(pageNum);
        console.log("######");
        */
    }

    const deleteThisPicture = async (picture) => {
      setProcessing(true); 
         try{

            const deleteObject = {
                "cloudId" : picture.cloudinaryid,
                "cloudIdSmall" : picture.cloudinaryidsmall,
                "pictureId" : picture._id
            }
    
            const res = await axios.put(CONSTANTS.API_URL +"pictures/remove/single/image", deleteObject, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });

           
            setProcessing(false);  
            
            setPictureList([]);
            setPageNum(1);   
            setHasMore(true);
         }catch(err){
          console.log("+++++++++");
          console.log(err);
          setProcessing(false); 
         }
    }

    const photoAlbumDelete = async () => {
      setProcessing(true);
        
        const albObject = {
          "albumCode" : currentAlbum.albumPicCode,
          "albumId" : currentAlbum._id
        }

        try{
          
          const resAlbumDel = await axios.put(CONSTANTS.API_URL +"pictures/remove/list/images", albObject, {
            headers: {
                    token: "Bearer "+ user.accessToken
                }
            });

            if(resAlbumDel.status == 200){
              toast.success(resAlbumDel.data);
            }
            setProcessing(false);
          
            toast.success("Album deleted Successfully.")
            navigate("/consumer/" + currentAlbum.userid);

        } catch(err){
          console.log(err);
          toast.error("Could not delete, please try again later")
        } 
      
    }
    const Loader = () => (
        <div className="img-source">
          <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
        </div>
    )

    async function HandleAddingAlbumPictures(e) {
        e.preventDefault();  
        setProcessing(true);   
           
            const picLength = imageDataRef.current.files.length;
        
            for (let i = 0; i < picLength; i++) {
                const file = imageDataRef.current.files[i];
               
                try {
                  const formData = new FormData();
                    formData.append("picurl", file);
                    formData.append("userid", user._id);
                    formData.append("albumPicCode", currentAlbum.albumPicCode);
                    
                    const response = await axios.post(CONSTANTS.API_URL+"pictures/upload/imagery" ,formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            token: "Bearer "+ user.accessToken
                        }
                     });

                  
                     if(response.status == 200){
                      setMessageResponse("Update added [ " + file.name + "]");
                     }
                  } catch (error) {
                    console.error('Error uploading image:', error);
                  }
            }

            setProcessing(false);  
        
            setPictureList([]);
            setPageNum(1);   
            setHasMore(true);
            
            // Reset the input field after submission
            if(imageDataRef.current) {
              imageDataRef.current.value = ''; // Resetting the input value
            }
    }

    async function handleSubmitCode(e) {
      e.preventDefault();  
        setProcessing(true); 
        try{
          
          var codeDownload = DownloadCodeRef.current.value;
          const statusObject = {
            "downloadCode": codeDownload,
            "albumid": currentAlbum._id
          }

          const response = await axios.put(CONSTANTS.API_URL+"albums/update/download/code", statusObject, {
                headers: {
                    token: "Bearer "+ user.accessToken
                }
            });
      
            //console.log(response);
            if(response.status == 201){
              setCurrentAlbum(response.data);
              toast.success("Code will allow the download of the pictures.")
            }
            
          setProcessing(false); 
        }catch(err){
          console.log(err);
        }
        setProcessing(false);    
    }

    const addingMaxCheckImages = (event) => {
        event.preventDefault();
        const selectedFiles = imageDataRef.current.files;
        
        if (selectedFiles.length > 90) {
            // Show error toast
            toast.error("Sorry, you can only upload 90 images.");
            // Clear file input
            imageDataRef.current.value = null;
            return;
        }
    };
    const updateAlbumDisplay = async (optionDisplay) => {
      try{
          const statusObject = {
            "displayType": optionDisplay,
            "albumid": currentAlbum._id
          }
    
          const response = await axios.put(CONSTANTS.API_URL+"albums/update/display/type", statusObject, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
    
          //console.log(response);
          if(response.status == 201){
            setCurrentAlbum(response.data);
          }

      }catch(err){
        console.log(err);
      }
    }

    const updateAlbumStatus = async (option) => {
      try{
          const statusObject = {
            "visible": option,
            "albumid": currentAlbum._id
          }
    
          const response = await axios.put(CONSTANTS.API_URL+"albums/update/status", statusObject, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });
    
      
          if(response.status == 201){
            setCurrentAlbum(response.data);
          }
      }catch(err){
        console.log(err);
      }
    
    }

    const downloadAllPicrues = async () => {
      try{  
        var codeString = checkCodeRef.current.value;

        if(codeString == currentAlbum.downloadCode){
          ////////////
          const d = new Date();
          var  dtSecs =  d.getTime();
          const folderName = "PhotoCut_Album_" + dtSecs;
          const zip = new JSZip();
         
          const fetchPromises = [];

            // Loop through each picture object in the pictureList array
            pictureList.forEach((picture, index) => {
                // Extract the URL of the image
                const imageUrl = picture.picurl;

                // Fetch image data as blob and add to fetch promises array
                const fetchPromise = fetch(imageUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        // Add the image file to the zip archive
                        zip.file(`PHOTOCUT_${index}.jpg`, blob, { binary: true });
                       
                    });
                
                fetchPromises.push(fetchPromise);
            });

            // Wait for all fetch promises to complete
            await Promise.all(fetchPromises);

            // Generate the zip file asynchronously
            zip.generateAsync({ type: "blob" })
                .then(blob => {
                    // Prompt the user to download the zip file
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = `${folderName}.zip`;
                    link.click();
                });

          toast.success("Images downloaded successfully");
          ////////////
        }else{
          toast.error("Code is incorrect for downloading Images")
        }

      }catch(err){
        console.log(err);
      }
    }

    const dialogModelClosing = () => {
      setModel(false);
      setTempingSrc("");
    }

    const breakpointColumnsObj = {
      default: 4,
      1400: 3,
      900: 2,
      700: 1,
    }
  
    const columnClassName = 'my-masonry-grid_column'
  
    const gutterSpace = '30px'
  
    const masonryStyles = {
      ml: `-${gutterSpace}`,
    }
  
    const selector = `& .${columnClassName}`
  
    masonryStyles[selector] = {
      pl: gutterSpace,
      backgroundClip: 'padding-box',
    }
    

  return (
    <div className="stream-container">
      <div className="upper-top">
          <TopNavigation />
      </div>
      <div className="content-display">
          <div className="initial-title">
            Single Album
          </div>
          <div className="right-flight">
            {
              currentAlbum && (
                <>
                <Link to={"/consumer/" + currentAlbum.userid}>Album List</Link>
                </>
              )
            }
          </div>
         
            {
              ((user != null) && (user != undefined) && (currentAlbum && (user._id === currentAlbum.userid)) ) && (
                <div className="row">
                     <div className="col-md-4">
                        
                        <div className="form-item form-pharm">
                      
                          {
                            currentAlbum && (<h3>{currentAlbum.title}</h3>)
                          }
                                <form onSubmit={HandleAddingAlbumPictures}>
                                    <div className="form-group">
                                        <div className="lbldesc">Album Pictures* <sub>90</sub>:</div>
                                        <input 
                                          type="file" 
                                          className="form-control wide100" 
                                          ref={imageDataRef} 
                                          accept="image/*"
                                          onChange={addingMaxCheckImages}
                                          multiple required/>
                                    </div>
                                
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-photocut" disabled={processing}>UPLOAD</button>                             
                                    </div>
                                </form>
                        </div>
                        <div className="result-feed">
                          Results
                              {
                                messageResp && (<div className="message-uploaded-list">{messageResp}</div>)
                              }
                        </div>
                        
                      </div>
                     
                      <div className="col-md-2">
                          <div className="privacy-block">
                            Visibility: 
                            {
                            currentAlbum && (
                              <>
                                {
                                  (currentAlbum.visible === true) ? 
                                    <div className="alb-cont">
                                      <div className="album-visit visitble">Album is public.</div>
                                      <button className="btn btn-photocut bt-low mgtop10" disabled={processing} onClick={() => updateAlbumStatus(false)}>
                                      Turn Album to private
                                      </button>
                                    </div>
                                      :
                                      <div className="alb-cont">
                                        <div className="album-visit private">Album is private.</div>
                                        <button className="btn btn-photocut bt-low mgtop10" disabled={processing} onClick={() => updateAlbumStatus(true)}>Turn Album to public</button>
                                      </div>
                                }
                              </>)
                            }
                            
                          </div>
                      </div>
                      <div className="col-md-2">
                            Download Code
                           
                            {
                              currentAlbum && (
                                <>
                                   <form onSubmit={handleSubmitCode}>
                                      <div className="flexme input-download">
                                        <input type="text" className="form-control wide100 unround-right" ref={DownloadCodeRef} defaultValue={currentAlbum.downloadCode}  required/>
                                        <button type="submit" className="btn btn-green" disabled={processing}><FaFileDownload /></button>  
                                      </div>
                                  </form>
                                </>
                              )
                            }
                      </div>
                      <div className="col-md-2">
                           <div className="display-type">
                              Delete this album.
                              <button onClick={() => setAlbumDeleteConfirmation(true)} className="btn btn-photocut bt-low mgtop10" ><FaTrash /> Delete NOW</button>  
                           </div>
                      </div>
                </div>
              )
            }
           
           <div className={model? "model open": "model"}>
                   <div className="full-lay">
                   <img src={tempingSrc}  className="full-image"/>
                     <button className="buttonCloser" onClick={() => dialogModelClosing()
                      }>
                       X
                     </button> 
                   </div>
            </div>
            <div className={albDeleteConfirmationModel? "model open": "model"}>
                   <div className="full-lay">
                     <div className="modal-dial">
                          <div className="modal-head">
                             Delete Confirmation
                          </div>
                          <div className="modal-bod">
                              <h4> Confirm to delete this alblum.</h4>
                              <button className="btn btn-photocut" onClick={() => photoAlbumDelete()}>Confirmation</button>
                          </div>
                          <div className="modal-foot">
                             <button className="btn btn-gray" onClick={() => setAlbumDeleteConfirmation(false)}>
                                X
                              </button> 
                          </div>
                     </div>                     
                   </div>
            </div>
           {
              currentAlbum && (
                <div className="album-box-area">
                   {
                       ((currentAlbum.visible == true) || (user && (user._id === currentAlbum.userid))) ? 
                      <>
                            {

                                (pictureList.length > 0) && (
                                  <>
                                     <InfiniteScroll
                                          next={reviewHassMore()}
                                          dataLength={pictureList.length}
                                          hasMore={hasMore}
                                          loader={<Loader />}
                                          >
                                            <Flex 
                                              className={columnClassName} 
                                              as={Masonry}
                                              breakpointCols={breakpointColumnsObj}
                                              sx={masonryStyles}
                                              mt='2rem'
                                              >
                                                {

                                                pictureList.map((album, key) => {
                                                          return <div className="pic-box" key={key} onClick={(e)=> getImage(album.picurl , e)}>
                                                                
                                                                  {
                                                                    (user && (user._id === currentAlbum.userid)) && (
                                                                      <div className="position-delete">
                                                                        <button className="btn btn-over btn-trasher" onClick={() => deleteThisPicture(album)}><FaTrash /></button>
                                                                      </div>
                                                                    )
                                                                  }
                                                                    <img  
                                                                        className="img-special"
                                                                        src={album.picurlsmall}
                                                                        loading="lazy" 
                                                                        alt={"PHOTO CUTTS " + currentAlbum.title}
                                                                        srcSet={`${album.picurlsmall} 1x, ${album.picurlsmall}?w=300 2x`}
                                                                        />   
                                                                                                                                     
                                                                </div>
                                                          })
                                               }
                                              </Flex>
                                     </InfiniteScroll> 
                                  </>                                  
                          )
                      }
                      <div className="pd30 download-pad">
                        {
                          currentAlbum && (
                            currentAlbum.downloadCode.length > 1 && (
                                <div className="flexme input-download">
                                  <input type="text" className="form-control unround-right" ref={checkCodeRef} placeholder="Add code to download"/>
                                  <button className="btn btn-green mob-item1" onClick={() => downloadAllPicrues()}><FaDownload /></button>
                                  <div className="flexme70 pd10">A code is required in order to be able to download this album.</div>
                                </div>
                              )
                            )
                        }
                      </div>
                    </>
                    :
                    <>
                        <div className="text-center">
                          <img src={imageRestriction} className="restrict-img" />
                        </div>
                    </>    
                  }
                </div>
              )
           }
        
           {
                processing && (
                    <div className="img-source">
                     <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                    </div>
                )
            }

      </div>
      <div className="below-bottom">
          <FooterHolder />
        </div>
    </div>
  )
}

export default AlbumSingle