import React, { useEffect, useState } from 'react'
import TopNavigation from '../Components/TopNavigation'
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";
import "./creatorsingle.css"; // Add your custom styles

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import FooterHolder from '../Components/FooterHolder';
import { Helmet } from 'react-helmet';

function ContentCreatorSingle() {
    
    const [processing, setProcessing]                                = useState(false);
    const {user}                                                     = useSelector((state) => state.auth);
  
    const params                                                     = useParams();

    const [creator, setCreator]                                      = useState(null);
    const [portfolioList, setPorfolioList]                           = useState([]);

    useEffect(() => {
        collectionOfSingleUser();
    },[])

    useEffect(() => {
        if(creator){
            collectionOfProfiles();
        }        
    },[creator])

    const collectionOfSingleUser = async () => {
        try{
          const creatorRep = await axios.get(CONSTANTS.API_URL + "users/creator/single/v1/" +  params.id);
          setCreator(creatorRep.data);
         // console.log(creatorRep);
        }catch(err){
            console.log(err);
        }
    }

    const collectionOfProfiles = async () => {
        try{
          const creatorPortFolios = await axios.get(CONSTANTS.API_URL + "portfolios/list/v2/" +  creator._id);
          setPorfolioList(creatorPortFolios.data);
         // console.log(creatorPortFolios.data);
        }catch(err){
            console.log(err);
        }
    }

  return (
    <div className="stream-container">
        <Helmet>
                <title>Photocutt - Creator Portfolios</title>
                <meta name="description" content="Beautiful stunning content portfolios ready for your viewing." />
        </Helmet>
        <TopNavigation />
        <div className="content-display">
            <div className="initial-title">
            Content Creator Portfolios
            </div>
            <div className="content-main content-image-list mid-swiper-area">
                {
                    portfolioList &&
                    portfolioList.length > 0 && (
                        <Swiper
                            modules={[Navigation, Pagination, EffectFade]}
                            spaceBetween={30}
                            slidesPerView={1.5} // Display two slides
                            centeredSlides={true}
                            onSlideChange={(swiper) => {
                                    console.log("Slide index changed to:", swiper.activeIndex);
                            }}
                            pagination={{ clickable: true }}
                            >
                            {portfolioList.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <div className="slide-content">
                                        {
                                            image.picurl ? 
                                            <img src={image.picurl} alt={"Photocutt " + image.title} />
                                            :
                                            <div className="pattern-layer"></div>
                                        }
                                        
                                        <div className="fade-text p-title">
                                            <h2>{image.title}</h2>
                                            <p>PORTFOLIO DETAILS</p>
                                            <Link to={"/single-portfolio/" + image._id} className="btn-profile-viewer">VIEW PROFILE</Link>
                                        </div>
                                        
                                    </div>
                                </SwiperSlide>
                                ))}
                        </Swiper>
                    )
                }                
            </div>
        </div>
        <div className="below-bottom">
          <FooterHolder />
        </div>
    </div>
  )
}

export default ContentCreatorSingle