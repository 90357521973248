import React from 'react'
import { FaWindowClose } from 'react-icons/fa'
import { toast } from 'react-toastify';
import * as CONSTANTS from "../../CONSTANTS";
import axios from 'axios';

function PorfolioModalDelete({user, currentPortfolio, setShowPorfolioDeleteModal, setProcessing})  {
  
     ////////////////////////////////////////////////////////////////
     const handleDeletePorftolio = async () => {
 
        const portDelete = {
          "portfolioId" : currentPortfolio._id,
          "userId": user._id
        } 
        ////////////////////////
        try{
            console.log(portDelete)
            
          setProcessing(true);
          
              const portResponse = await axios.put(CONSTANTS.API_URL +"portfolios/delete/current/item/v1", portDelete, {
                    headers: {
                        token: "Bearer "+ user.accessToken
                    }
                });
  
               // console.log(portResponse);
                toast.success(portResponse.data.message);            
                setProcessing(false);
                window.location.replace("/this-creator/" + user._id);
                
          }catch(err){
             console.log(err);
             toast.error("Something went wrong, please try again later.")
             setProcessing(false); 
          }
        ////////////////////////
      }
  return (
    <div className="modal-data-view">
        <div className="modal-head">
            <h4>Portfolio Delete</h4>
            <div className="closing">
                <button className="btn-closer" onClick={() => setShowPorfolioDeleteModal(false)}>
                    <FaWindowClose /> 
                </button>
            </div>
        </div>
        <div className="modal-body">
            <div className="content-modal">
                <div className="post-item text-center">
                    <h3>Please confirm to delete portfolio '<i>{currentPortfolio.title}</i>'</h3>
                    <button className="btn btn-photocut mgtop20 mgbot20" onClick={handleDeletePorftolio}>Delete</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PorfolioModalDelete