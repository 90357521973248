import React, { useEffect, useRef, useState } from 'react';
import './authStyle.css';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../Components/Spinner';
import axios from "axios";
import * as CONSTANTS from "./../CONSTANTS";
import navLog from './../assets/logo-x1.png';

import backImageBaby from './../assets/photocut-baby.webp';
import backImageCamera from './../assets/photocut-camera.webp';
import backImageLandscape from './../assets/photocut-landscape.jpeg';
import backImageWedding from './../assets/photocut-wedding-pilla.jpg';

function AuthReset() {

    const [isLoading, setIsLoading]                                       = useState(false);

    const [validateCode, setValidateCode]                                 = useState("");
    const [inputValidateCode, setInputValidateCode]                       = useState("-");
    const [emailValid, setEmailValid]                                     = useState("");
    

    const emailRef                                                        = useRef();
    const passwordRef                                                     = useRef();

    const [accessTokenCheck, setAccessTokenCheck]                         = useState(false);

    useEffect(() => {
        if(inputValidateCode === validateCode){
          setAccessTokenCheck(true);
        }else {
          setAccessTokenCheck(false);
        }
    },[inputValidateCode]);

    const handleReset = async (e) => {
      e.preventDefault();

        let emailCheckTest = isValidEmail(emailRef.current.value);
        if(emailCheckTest){
          setIsLoading(true);
           const emailItem = {
            "email" : emailRef.current.value
           }
           setEmailValid(emailRef.current.value);

           const res = await axios.put(CONSTANTS.API_URL +"users/current/email/change", emailItem);
          
           toast.success("Your reset request was successfull, please check your email address.");
           setValidateCode(res.data.accessCode);
           setIsLoading(false);
        }else {
            toast.error("Email not valid");
            setIsLoading(false);
        }
    }

    function isValidEmail(email){
        return /\S+@\S+\.\S+/.test(email);
    }

    if (isLoading) {
      return  <Spinner />
    }

    const handlePasswordChange = async () => {
      try{
          const passWordReset = {
            "resetCode" : validateCode,
            "password" : passwordRef.current.value,
            "email" : emailValid
          }
          setIsLoading(true);
          const res = await axios.put(CONSTANTS.API_URL +"users/reset/direct/", passWordReset);
          
          
          toast.success(res.data.message);

          setIsLoading(false);
          toast.success("Please sign in.");
      }catch(err){
        setIsLoading(false);
        toast.success("Something went wrong");
      }
    }

  return (
    <>
      <div id="CrossFade">
        <img src={backImageWedding} alt="PHOTOCUT WEDDING"/>        
        <img src={backImageCamera} alt="PHOTOCUT CAMERA"/>
        <img src={backImageLandscape} alt="PHOTOCUT LANDSCAPE"/>
        <img src={backImageBaby} alt="PHOTOCUT BABY"/>        

          <div className="form-container sign-in-container reset-in-cont">
             <div className="base-logo" style={{ 
                    backgroundImage: `url(${navLog})` 
                    }}>

             </div>
             <form onSubmit={handleReset} className="auther">
              
                <h2>Reset Account</h2>            
                
                <input id="emaillog" className="mgInput" type="email" placeholder="Email"  ref={emailRef}  required=""/>
                
                <button id="sign-in">Reset</button>
            </form>

            {
              validateCode.length === 5 && (
                <div className="access-form">
                  <input type="text" className="mgInput2" onChange={(e) => setInputValidateCode(e.target.value)} placeholder="Email Access Code"/>

                  {
                    accessTokenCheck === true && (
                        <div className="lay-password">
                           <input type="password" className="mgInput2" ref={passwordRef} placeholder="New Password" />
                           <button onClick={() => handlePasswordChange()} className="btn-photocut">Save</button>
                        </div>
                    )
                  }
                
                </div>
              )
            }
            <div className="extra-links-auth">
              <div className="flexme inner-distant">
                <Link to="/">Home</Link>
                <Link to="/login">Sign In</Link>
              </div>
            </div>
            
          </div>
      </div>
    </>
  )
}

export default AuthReset