import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import FooterHolder from '../Components/FooterHolder';
import TopNavigation from '../Components/TopNavigation';
import axios from "axios";
import { toast } from 'react-toastify';
import * as CONSTANTS from "./../CONSTANTS";
import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

function ContentCreator() {
    
    const [processing, setProcessing]                             = useState(false);
    const {user}                                                  = useSelector((state) => state.auth);
    
    const [listCreators, setListCreators]                         = useState([]);

    useEffect(() => {
        collectionOfCreators();
    },[])

    const collectionOfCreators = async () => {
        try{
            const response = await axios.get(CONSTANTS.API_URL + "users/content/creators/v1");
            //console.log(response.data);
            setListCreators(response.data);
        }catch(err){
            console.log(err)
        }
    }

  return (
    <div className="stream-container">
        <Helmet>
                <title>Photocutt - Content Creators</title>
                <meta name="description" content="Go through some of the best artistic photography, videography, cinematography artists in the land, on the Photocutt space." />
        </Helmet>
      <TopNavigation />
      <div className="content-display">
          <div className="initial-title">
            Content Creators
          </div>
          <div className="content-main content-area-layer2">
            <div className="row">
                {
                    listCreators && 
                    listCreators.length > 0 && (
                        listCreators.map((listing, index) => {
                            return <div className="col-md-2" key={index}>
                                <div className="cicile-card">
                                    <div className="title-creator">
                                        <Link to={"/this-creator/" + listing._id}
                                            className="prof-link">
                                           {listing.name} {listing.surname}
                                        </Link>
                                    </div>
                                    
                                    <div className="profile-item-lst">
                                        <Link to={"/this-creator/" + listing._id}
                                            className="prof-link">
                                            {
                                                listing.profilePic ? (
                                                    <img 
                                                        src={listing.profilePic} 
                                                        alt={`Photocutt - ${listing.name} ${listing.surname}`} 
                                                        className="fit-profiles"
                                                    />
                                                ) : (
                                                    <FaUser />
                                                )
                                            }
                                        </Link>
                                    </div>
                                    <div className="titling-creator ">
                                        <Link to={"/this-creator/" + listing._id}
                                              className="prof-link">{listing.profession}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        })
                    )
                }
            </div>
          </div>
      </div>
      <div className="below-bottom">
          <FooterHolder />
        </div>
    </div>
  )
}

export default ContentCreator