import React, { useEffect, useState } from 'react'
import * as CONSTANTS from "./../CONSTANTS";
import axios from "axios";
import { Link, useParams } from 'react-router-dom';
import TopNavigation from '../Components/TopNavigation';
import FooterHolder from '../Components/FooterHolder';

function Consumer() {
    const [myAlbums, setMyAlbums]                                   = useState([]);

    const params                                                    = useParams();

    useEffect(() => {
        collectCurrentDetails();
    },[])

    const collectCurrentDetails = async () => {
        try {
        
            console.log(params.id);
            const res = await axios.get(CONSTANTS.API_URL +"users/consumer/details/"+ params.id);
  
            console.log("(((((((============>")
            console.log(res.data);
            setMyAlbums(res.data.albums);

          } catch (err) {
            console.log(err);            
          }
    }

  return (
    <div className="stream-container">
        <TopNavigation />
        <div className="content-display">
           <div className="row">
              {

                myAlbums.length > 0 ?
                <>
                  {
                      myAlbums.map((alby, index) => {
                        return (<div className="col-md-3" key={index}>
                                    <div className={"album-block tpnum"+ (index & 1) }>
                                      <h4><Link to={"/single-album/" + alby._id}>{alby.title}</Link></h4>
                                    </div>
                                </div>)
                      })
                    }
                </>
                : 
                 <>
                   <div className="a1Size">No Albums Create</div>
                   <div className="a2Size">Please creae an album of yourself.</div>
                 </>
              }
           </div>
        </div>
        <div className="below-bottom">
          <FooterHolder />
        </div>
    </div>
  )
}

export default Consumer