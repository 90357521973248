import React, { useEffect, useState } from 'react'
import TopNavigation from '../Components/TopNavigation';
import FooterHolder from '../Components/FooterHolder';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../reduxAuth/authSlice';

function Portfolios() {
  const [processing, setProcessing]                             = useState(false);
  const {user}                                                  = useSelector((state) => state.auth);
  const [portfolioData, setPortfolioData]                       = useState([]);
  
  const navigate                                                = useNavigate();
  const dispatch                                                = useDispatch();

  useEffect(() => {
    collectMyPortfolios();
  },[])

  const collectMyPortfolios = async () => {
    try{

      const response = await axios.get(CONSTANTS.API_URL + "portfolios/list/v1/" + user._id, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

          setPortfolioData(response.data);

    }catch(error){
      console.log(error);
        if(error.response.statusText ===  "Unauthorized"){
            dispatch(logout());
            navigate("/login");
        }
    }
  }

  return (
    <div className="stream-container">
        <TopNavigation />
        <div className="content-display">
            <div className="initial-title">
            Portfolios
            </div>
            <div className="content-main row-content">
              {
                 portfolioData && 
                 portfolioData.length > 0 && (
                  <div className="row">
                      {
                        portfolioData.map((portf, index) => {
                          return <div className="col-md-4" key={index} >
                                    <div 
                                      className="tpnum0 back-aremy"
                                      style={{ backgroundImage: portf.picurl ? `url(${portf.picurl})` : 'none' }}
                                      >
                                        <Link to={"/single-portfolio/" + portf._id}>
                                            <h3>{portf.title}</h3>
                                        </Link>
                                    </div>
                                </div>
                        } )
                      }
                  </div>
                 )
              }
            </div>
        </div>
        <div className="below-bottom">
            <FooterHolder />
        </div>
    </div>
  )
}

export default Portfolios