import React, { useRef, useState } from 'react';
import { FaClosedCaptioning, FaPlay, FaWindowClose } from "react-icons/fa";
import { motion, useScroll, useTransform} from "framer-motion";

function PortfolioVideoList({videoList}) {

  return (
    <div className="row row-laying">
        <div className="row-scroll-down">
          <h4 className="s-tst"></h4>
        </div>

        <HorizontalScrollCarrousel videoList={videoList} />

        <div className="row-scroll-up">
           <h4 className="s-tst"></h4>
        </div>
    </div>
  )
}

const HorizontalScrollCarrousel = (videoList) => {

  const [currentVideo, setCurrentVideo]                   = useState(null);
  const targetRef                                         = useRef(null)

  const { scrollYProgress } = useScroll({
      target: targetRef
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-95%"]);

  return <section ref={targetRef} className="base-horizontal">
          <div className="video-work-title">Video Content</div>
          {
              currentVideo !== null && (
                  <div class="modal-view-content">
                      <button onClick={() => setCurrentVideo(null)} className="btn-closer-bin"><FaWindowClose /> </button>
                      <div className="video-frame-play"   style={{
                          animation: "dropTop .5s linear"
                      }}>
                      <iframe  src={currentVideo.videoUrl}>
                      </iframe>
                      </div>
                  </div>
              )
          }
         
      <div className="pstick h-screen justflex ptop80">         
          <motion.div style={{x}} className="dflex gap4">
          {
              videoList.videoList.map((video, index) => {
                  return <div key={index} className="card-block"                       
                      onClick={() => setCurrentVideo(video)}>
                              <div className="video-space-click">
                              <img 
                                   src={"https://img.youtube.com/vi/" + extractVideoID(video.videoUrl) + "/hqdefault.jpg"} 
                                   alt="YouTube Video Thumbnail"
                                   className="video-embedImage black-bone"
                                  />

                                  <div className="vidframe">                                                                        
                                      <div className="play-contaner">
                                          <FaPlay />
                                      </div>                                      
                                  </div>                                       
                              </div>        
                      </div>
                  })
          }  
          </motion.div>
      </div>
  </section>
}

function extractVideoID(url) {
  const urlParts = url.split('/');
  const lastPart = urlParts[urlParts.length - 1];
  const videoID = lastPart.split('?')[0];
  return videoID;
}
export default PortfolioVideoList