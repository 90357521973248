import React, { useRef, useState } from 'react';
import { FaUser} from 'react-icons/fa';
import * as CONSTANTS from "./../../CONSTANTS";
import axios from "axios";
import { updateUser } from '../../reduxAuth/authSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {getCroppedImg} from '../helperWidgets/getCroppedImg';
import Cropper from 'react-easy-crop';

function ProfileWidget({user}) {

  const [processing, setProcessing]             = useState(null);

  const [modalOpen, setModalOpen]               = useState(false);
  const dispatch                                = useDispatch();
  const [imagepreview, setImagePreview]         = useState(CONSTANTS.PREVIEW_IMAGE);

  const [imageSrc, setImageSrc]           = useState(null);
  const [crop, setCrop]                   = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const fileImageRef = useRef();

  const personNameRef                        = useRef();
  const personSurNameRef                     = useRef();
  const personPhoneNumberRef                 = useRef();
  const personProfessionrRef                 = useRef();

  const personFacebookRef                  = useRef();
  const personInstagramRef                 = useRef();
  const personTwitterRef                   = useRef();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setImageSrc(reader.result);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  //Update socials
  async function handleUpdateSocials(e){
    e.preventDefault();   
      try{
        setProcessing(true);
        const socials = {
          "identity": user._id,
          "facebook" : personFacebookRef.current.value,
          "instagram" : personInstagramRef.current.value,
          "twitter" : personTwitterRef.current.value
        }
      
      const resSocials = await axios.put(CONSTANTS.API_URL +"users/socials/details", socials, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

        console.log("++++++++++++++++++++++++++++");
        
          if(resSocials.status == 200){
            ///////////////===>
              const updatedUser = {
                  ...resSocials.data,
                  accessToken: user.accessToken 
              };
                
            // Store the updated user object in local storage
            localStorage.setItem(CONSTANTS.SESSION_COOKIE, JSON.stringify(updatedUser));

              // Update the user state in the Redux store
              dispatch(updateUser(updatedUser));
            ///////////////===>
            toast.success("User socials have been updated.");
          }
          setProcessing(false);
      }catch(err){
        console.log(err);
        toast.error("User update was not successfull, please try again later.");
        setProcessing(false);
      }
  }

  ///Update details
  async function handleUpdateDetails(e){
    e.preventDefault();   
    try{
      setProcessing(true);
      const details = {
        "identity": user._id,
        "name" : personNameRef.current.value,
        "surname" : personSurNameRef.current.value,
        "phonenumber" : personPhoneNumberRef.current.value,
        "profession" : personProfessionrRef.current.value
      }
  
      const resDetails = await axios.put(CONSTANTS.API_URL +"users/basic/details", details, {
            headers: {
                token: "Bearer "+ user.accessToken
            }
        });

    console.log("++++++++++++++++++++++++++++");
    
      if(resDetails.status == 200){
        ///////////////===>
          const updatedUser = {
              ...resDetails.data,
              accessToken: user.accessToken 
          };
            
        // Store the updated user object in local storage
        localStorage.setItem(CONSTANTS.SESSION_COOKIE, JSON.stringify(updatedUser));

          // Update the user state in the Redux store
          dispatch(updateUser(updatedUser));
        ///////////////===>
        toast.success("User socials have been updated.");
      }
      setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
      toast.error("User update was not successfull, please try again later.");
    }
  }

  async function handleFormUpload(e) {
      e.preventDefault();   
    try{

      setProcessing(true);
       // Crop the image
       const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
            
          const formData = new FormData();
          formData.append('croppedImage', croppedImage);
          formData.append('identity', user._id);
          formData.append('cloudinaryid', user.cloudinaryid || '');
            
      
          const resProfile = await axios.post(CONSTANTS.API_URL + "users/profile/image", formData, {
              headers: {
                token: "Bearer " + user.accessToken,
              },
            });
    
          if (resProfile.status === 200) {
            const updatedUser = {
              ...resProfile.data,
              accessToken: user.accessToken,
            };
    
            localStorage.setItem(CONSTANTS.SESSION_COOKIE, JSON.stringify(updatedUser));
            dispatch(updateUser(updatedUser));
            toast.success("User avatar has been updated.");
          }
    
      setProcessing(false);
    }catch(err){
      console.log(err);
      setProcessing(false);
      toast.error("User update was not successfull, please try again later.");
    }
    ///////////////////////////////////
  };
  
  return (
    <div className="tablet-widget">      
      <div className="initial-title">Edit Profile</div>
      <div className="edit-section edit-profile-beam">
          <div className="image-edit">
            {
                  user.profilePic == "" ?
                  <div className="icon-favicon">
                    <FaUser /> 
                  </div>
                  :
                  <div className="profile-pic">
                    <div className="profile-item-x1" style={{
                        backgroundImage: `url(${user.profilePic})` 
                    }}></div>
                  </div>
              }
           </div>
           <div className="pd10">
              <button className="btn-photocut" onClick={toggleModal}>
                Change Photo
              </button>               
           </div>
      </div>
      {
          processing && (
              <div className="img-source">
              <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
              </div>
          )
      }

      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header-sect">
              <div className="">Change Photo</div>
              <span className="close" onClick={toggleModal}>&times;</span>
            </div>
            <div className="modal-body-sect">
               <div className="form-container">
                    <form onSubmit={handleFormUpload}>
                      <div className="form-group">
                        <input 
                          type="file" 
                          className="form-control w80" 
                          onChange={onFileChange} 
                          ref={fileImageRef}  
                          accept="image/*" 
                          required/>
                          
                      </div>
                      <div className="form-group">
                         {/* <img className="previewItem" src={imagepreview} alt="Photocutt Preview image" /> */}
                         {imageSrc && (
                              <div className="crop-container">
                              <Cropper
                                  image={imageSrc}
                                  crop={crop}
                                  zoom={zoom}
                                  aspect={1}
                                  onCropChange={setCrop}
                                  onZoomChange={setZoom}
                                  onCropComplete={onCropComplete}
                              />
                              </div>
                          )}
                      </div>                      
                        <button type="submit" className="btn btn-photocut" disabled={processing}>
                          {processing ? "Processing..." : "Submit Image"}
                        </button>
                    </form>
               </div>
            </div>
          </div>
        </div>
      )}
      <div className="edit-section edt-bridge edit-details-beam">
          <form onSubmit={handleUpdateDetails}>
            <div className="text-edit-brigde">
              <div className="form-group frm-profile">
                  Name*:
                  <input type="text" ref={personNameRef} className="form-control" defaultValue={user.name} required/>
              </div>
              <div className="form-group frm-profile">
                  Surname*:
                  <input type="text" ref={personSurNameRef} className="form-control" defaultValue={user.surname} required/>
              </div>
              <div className="form-group frm-profile">
                  Phone Number:
                  <input type="text" ref={personPhoneNumberRef} className="form-control" defaultValue={user.phonenumber}/>
              </div>
              <div className="form-group frm-profile">
                 Profession:
                 <input type="text" ref={personProfessionrRef} className="form-control" defaultValue={user.profession} />
              </div>
            </div>
            <div className="update-button-sec">
               <button type="submit" className="btn btn-photocut" >Update Details</button>
            </div>
          </form>
          <div className="pd10">

          </div>
      </div>
      <div className="edit-section  edt-bridge edit-socials-beam">
        <div className="text-edit-brigde">
        <form onSubmit={handleUpdateSocials}>
            <div className="text-edit-brigde">
             
              <div className="form-group frm-profile">
                  Facebook:
                  <input type="text" ref={personFacebookRef} className="form-control" defaultValue={user.facebook} />
              </div>
              <div className="form-group frm-profile">
                  instagram:
                  <input type="text" ref={personInstagramRef} className="form-control" defaultValue={user.instagram}/>
              </div>
              <div className="form-group frm-profile">
                 Twitter:
                 <input type="text" ref={personTwitterRef} className="form-control" defaultValue={user.twitter} />
              </div>
            </div>
            <div className="update-button-sec">
               <button type="submit" className="btn btn-photocut" >Update Socials</button>
            </div>
          </form>
          <div className="pd10">
              
            </div>
        </div>
        <div className="update-button-sec"></div>
      </div>
    </div>
  )
}

export default ProfileWidget