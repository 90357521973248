import axios from 'axios';
import React, { useState } from 'react'
import { FaWindowClose } from 'react-icons/fa'
import { toast } from 'react-toastify';
import * as CONSTANTS from "../../CONSTANTS";

function PorfolioModalVideos({user, currentPortfolio, setPorfolioVideoEditingModal, setProcessing, processing, setCurrentPortfolio}) {

    const [titleVid, setTitleVid]                           = useState("");
    const [urlVid, setUrlVid]                               = useState("")
    const [tagsVid, setTagsVid]                             = useState([]);
    const [currentTag, setCurrentTag]                       = useState("");

    const [activeTab, setActiveTab]                         = useState("tab1");

    const [selectedVideos, setSelectedVideos]               = useState(null);


    const HandleVideosToPortfolio = async (e) => {
        e.preventDefault();
  
        try{
          setProcessing(true);
          
          const objectPorfolio = {
              "title" : titleVid,
              "url" : urlVid,
              "tags" : tagsVid,
              "portfolioId" : currentPortfolio._id,
              "userId" : user._id
            }
          
            const res = await axios.post(CONSTANTS.API_URL +"portfolios/add/video/v1", objectPorfolio, {
                  headers: {
                      token: "Bearer "+ user.accessToken
                  }
              });
              
            if(res.status === 203){
                toast.warning("Your have reached the prtfolio limit.");
            }else{
                toast.success("Your video link has been added into your portfolio.");
                setCurrentPortfolio(res.data);
                setTitleVid("");
                setUrlVid("");
                setTagsVid([]);
            }
              
             //setPortfolioURL(res.data._id);
              
          setProcessing(false);
        }catch(err){
          console.log(err);
          toast.error("Something went wrong, please try again later.");
          setProcessing(false)
        }
    }

    const handleUrlInput = (input) => {
        try {
          // Extract the src attribute from the iframe input
          const iframeMatch = input.match(/src="([^"]+)"/);
          if (iframeMatch && iframeMatch[1]) {
            setUrlVid(iframeMatch[1]); // Set the extracted src to urlVid
          } else {
            setUrlVid(input); // If no iframe found, use the input as-is
          }
        } catch (error) {
          console.error("Invalid iframe HTML input:", error);
        }
    };

      // Add a tag to the list
    const handleAddingTag = () => {
        if (currentTag.trim() !== "" && !tagsVid.includes(currentTag.trim())) {
        setTagsVid([...tagsVid, currentTag.trim()]);
        setCurrentTag(""); // Clear the input field
        }
    };
    
    // Remove a tag from the list by index
    const handleRemoveTag = (index) => {
        setTagsVid(tagsVid.filter((_, i) => i !== index));
    };

    ////////////////////////////////////////////////////////////////
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    ////////////////////////////////////////////////////////////////
    const handleDelete = async (videoItem) => {
 
      const portDelete = {
        "portfolioId" : currentPortfolio._id,
        "videoId" : videoItem._id
      } 
      ////////////////////////
      try{
        setProcessing(true);
        
            const portResponse = await axios.put(CONSTANTS.API_URL +"portfolios/delete/video/v1", portDelete, {
                  headers: {
                      token: "Bearer "+ user.accessToken
                  }
              });

             // console.log(portResponse);
              toast.success(portResponse.data.message);
              setCurrentPortfolio(portResponse.data.portfolio);
              setSelectedVideos(null);
              setProcessing(false);
        }catch(err){
           console.log(err);
           toast.error("Something went wrong, please try again later.")
           setProcessing(false); 
        }
      ////////////////////////
    }
  return (
    <div className="modal-data-view">
        <div className="modal-head">
            <h4>Portfolio Videos</h4>
            <div className="closing">
                <button className="btn-closer" onClick={() => setPorfolioVideoEditingModal(false)}>
                    <FaWindowClose/> 
                </button>
            </div>
        </div>
        <div className="modal-body">
            <div className="content-modal">
                <div className="row">
                       <div className="view-videos">
                       <div className="tab-container">
                              {/* Tab Pills */}
                              <div className="tab-pills">
                                <button
                                  className={`tab-pill ${activeTab === "tab1" ? "active" : ""}`}
                                  onClick={() => handleTabClick("tab1")}
                                >
                                  Add Video
                                </button>
                                <button
                                  className={`tab-pill ${activeTab === "tab2" ? "active" : ""}`}
                                  onClick={() => handleTabClick("tab2")}
                                >
                                  Editing
                                </button>
                              </div>

                              {/* Tab Content */}
                              <div className="tab-content">
                                {activeTab === "tab1" && <div className="k-tablet-body">
                                      <div className="video-ctx-panel">
                                        <div className="add-video">
                                              <form onSubmit={HandleVideosToPortfolio}>
                                                    <div className="form-group">
                                                        <div className="lbldesc">Title Name*:</div>
                                                        <input
                                                          type="text"
                                                          className="form-control wide100"
                                                          required
                                                          value={titleVid}
                                                          onChange={(e) => setTitleVid(e.target.value)}
                                                          />
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="lbldesc">Embed URL:</div>      
                                                        <input
                                                              type="text"
                                                              className="form-control wide100"
                                                              value={urlVid}
                                                              onChange={(e) => handleUrlInput(e.target.value)}
                                                              placeholder="Paste iframe HTML here"
                                                              required
                                                              />                              
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                        <div className="lbldesc">Tags*:</div>
                                                        <div className="tag-list">
                                                          {tagsVid.map((tag, index) => (
                                                              <span key={index} className="tag">
                                                              {tag} <button type="button" className="btn btn-clx" onClick={() => handleRemoveTag(index)}>x</button>
                                                              </span>
                                                          ))}
                                                        </div>
                                                        <div className="input-tags">
                                                              <input
                                                                  type="text"
                                                                  className="form-control wide75"
                                                                  value={currentTag}
                                                                  onChange={(e) => setCurrentTag(e.target.value)}
                                                                  placeholder="Enter a tag"
                                                              />
                                                              <button
                                                                  type="button"
                                                                  className="btn btn-small"
                                                                  onClick={handleAddingTag}
                                                              >
                                                                  Add Tag
                                                              </button>
                                                          </div>
                                                    </div> 
                                                    
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-photocut" disabled={processing}>Create</button>                             
                                                    </div>
                                              </form>
                                            </div>
                                      </div>
                                  </div>}
                                {activeTab === "tab2" && <div className="k-tablet-body">
                                  <div className="video-ctx-panel">
                                            {
                                              currentPortfolio.videoItems.length > 0 && (
                                                <div className="scroll-video-horo">
                                                  {
                                                    currentPortfolio.videoItems
                                                    .slice() // Create a copy to avoid mutating the original array
                                                    .reverse() // Reverse the order of the array
                                                    .map((video, index) => {
                                                      return <div className="good-video" key={index} onClick={() => setSelectedVideos(video)}>
                                                                <div className="sm-video">
                                                                    <h4>{video.title}</h4>
                                                                  </div>
                                                            </div>
                                                    })
                                                  }
                                                </div>
                                              )
                                            }
                                            <div className="selected-video-view">
                                               {
                                                  selectedVideos &&
                                                  <div className="flexme">
                                                    <div className="flexme50">
                                                      <div className="sel-video">
                                                        <div className="pd10">
                                                          <iframe className="frame-pd" src={selectedVideos.videoUrl}>
                                                          </iframe>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="flexme50">
                                                      <div className="sel-video">
                                                          <div className="pd10">
                                                            <div className="sel-video-inner">
                                                                <center> 
                                                                  <button className="btnCloser" onClick={() => handleDelete(selectedVideos)}>Delete Video</button>
                                                                </center>
                                                                <h4>{selectedVideos.title}</h4>
                                                                <div className="tag-list">
                                                                   {
                                                                      selectedVideos.tags.length > 0 && (
                                                                        <ul className="tg-items">
                                                                            {
                                                                              selectedVideos.tags.map((tagName, index) => {
                                                                                return <li key={index}>{tagName}</li> 
                                                                              })
                                                                            }
                                                                        </ul>
                                                                      ) 
                                                                   }
                                                                </div>
                                                            </div>
                                                            
                                                          </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                               }
                                            </div>
                                        </div>
                                </div>}
                              </div>
                        </div>
                           
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default PorfolioModalVideos