import axios from 'axios';
import React, { useEffect, useState } from 'react'
import * as CONSTANTS from "./../CONSTANTS";
import { FaCamera } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../reduxAuth/authSlice';
import { useDispatch } from 'react-redux';

function HomeStartCreators() {

    const [currentCreator, setCurrentCreator]                = useState(null);

    const navigate                                           = useNavigate();
    const dispatch                                           = useDispatch();

    useEffect(() => {
        getRandomContentCreator();
    }, [])

    const getRandomContentCreator = async () => {
        try{
            const creatorPerson = await axios.put(CONSTANTS.API_URL + "users/creator/single/v1/randomized");
            //console.log("saaaa");
            //console.log(creatorPerson);
            setCurrentCreator(creatorPerson.data[0]);
        }catch(error){
            console.log(error);
            if(error.response.statusText ===  "Unauthorized"){
                dispatch(logout());
                navigate("/login");
            }
        }
    }

  return (
    <div className="widget-home">
       <div className="home-widg-profile">
        <div className="starting-content">
            <h1>Platform for facilitating professional portfolio work for artists.</h1>
        </div>
         <div className="container">
            {
                currentCreator && (
                    <div className="row">
                        <div className="col-md-6">
                            <div className="operation-widget operation1">
                            <div className="stream-wgt-hm"></div>
                                <div className="name-details">
                                    <h2>{currentCreator.name}</h2>
                                    <h2>{currentCreator.surname}</h2>
                                </div>
                                <div className="title-job-details">
                                    {currentCreator.profession}
                                </div>
                                <div className="align-job-details">
                                    <p><span>Phone</span>: {currentCreator.phonenumber}</p>
                                    <p><span>Emai</span>: {currentCreator.email}</p>
                                    {
                                    currentCreator?.facebook.length > 0 && (<p><span>Facebook</span>: @{currentCreator.facebook}</p>)
                                    }
                                    {
                                    currentCreator?.instagram.length > 0 && (<p><span>Instagram</span>: @{currentCreator.instagram}</p>)
                                    }
                                    {
                                    currentCreator?.twitter.length > 0 && (<p><span>Twitter</span>: @{currentCreator.twitter}</p>)
                                    }
                                </div>
                            </div>
                        </div>  
                        <div className="col-md-6">
                            <div className="operation-widget operation1">
                                <div className="home-color-lay">
                                    <div className="artistic-1">
                                       <FaCamera />
                                       <p>Content Creator</p>
                                    </div>
                                    <div className="close-look">                                        
                                        <Link to={"/this-creator/" + currentCreator._id}
                                            className="btn-hover-fade">
                                            My Work
                                        </Link>
                                    </div>
                                </div>
                                <div className="imag-possible">
                                    <div className="back-line-image"></div>
                                    {
                                        currentCreator.profilePic.length > 3 && (<img src={currentCreator.profilePic} alt={currentCreator.name} className="role-image-home"/>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }                
         </div>
       </div>
    </div>
  )
}

export default HomeStartCreators