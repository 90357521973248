import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthLogin from "./Pages/AuthLogin";
import AuthRegistration from "./Pages/AuthRegistration";
import Home from "./Pages/Home";
import AlbumCreate from "./Pages/AlbumCreate";
import MyAlbums from "./Pages/MyAlbums";
import AlbumSingle from "./Pages/AlbumSingle";
import Settings from "./Pages/Settings";
import Consumer from "./Pages/Consumer";
import About from "./Pages/About";
import TermsOfUse from "./Pages/TermsOfUse";
import Privacy from "./Pages/Privacy";
import InfinteGrid from "./Pages/InfinteGrid";
import AuthReset from "./Pages/AuthReset";
import PortfolioCreate from "./Pages/PortfolioCreate";
import Portfolios from "./Pages/Portfolios";
import PortfolioSingle from "./Pages/PortfolioSingle";
import ContentCreator from "./Pages/ContentCreator";
import ContentCreatorSingle from "./Pages/ContentCreatorSingle";
import ReactGA from "react-ga4";
ReactGA.initialize("G-R9S48SH5SX");

function App() {
  
  return (
    <>
       <Router>
         <div className="main-container">
          <Routes>
             <Route path="/login" element={<AuthLogin />}/>  
             <Route path="/register" element={<AuthRegistration />}/>  
             <Route path="/reset" element={<AuthReset />} />
             <Route path="/" element={<Home />}/>  
             <Route path="/create-portfolio" element={<PortfolioCreate />}/>
             <Route path="/portfolios" element={<Portfolios />}/>  
             <Route path="/single-portfolio/:id" element={<PortfolioSingle />}/>  
             <Route path="/create-album" element={<AlbumCreate />}/>
             <Route path="/my-albums" element={<MyAlbums />}/>  
             <Route path="/single-album/:id" element={<AlbumSingle />}/>  
             <Route path="/consumer/:id" element={<Consumer />}/> 
             <Route path="/settings" element={<Settings />}/>  
             <Route path="/about" element={<About />}/> 
             <Route path="/grid-scroll" element={<InfinteGrid />}/> 
             <Route path="/terms-of-use" element={<TermsOfUse />}/> 
             <Route path="/privacy" element={<Privacy />}/>         
             <Route path="/content-creators" element={<ContentCreator />} />   
             <Route path="/this-creator/:id" element={<ContentCreatorSingle />} />     
          </Routes>
        </div>
       </Router>
       <ToastContainer />
    </>
  );
}

export default App;
