import React, {useEffect, useState} from 'react';
import './topNav.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars , FaStop, FaUser} from 'react-icons/fa';
import navLogLand from './../assets/logo-regular-x2.png';
import { logout, reset } from './../reduxAuth/authSlice';
import axios from 'axios';
import * as CONSTANTS from "./../CONSTANTS";

function TopNavigation() {
    const {user}                                          = useSelector((state) => state.auth);
    const [showSideNavigation, setShowSideNavigation]     = useState(false);

    const navigate          = useNavigate();
    const dispatch          = useDispatch();

  const onLogOut = () => {

    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  }

  return (
    <div className="stream-head">
      <div className="upper-top">
          <div className="nav-band">
            <div className="navbar-inner">
                <div className="lp-box flexme">
                    <div className="logo">
                        <Link to="/">
                          <img src={navLogLand} className="logo-nav-image" />
                        </Link>
                      </div>
                      {
                        user && (
                          <>
                            <div className="logo-profile">
                              {
                                user.profilePic == "" ?
                                <div className="icon-favicon">
                                  <FaUser /> 
                                </div>
                                :
                                <div className="profile-pic-box">
                                  <div className="profile-item" style={{
                                      backgroundImage: `url(${user.profilePic})` 
                                  }}></div>
                                </div>
                              }
                            
                            </div>
                            <div className="naming-header">
                              {user.name} {user.surname}
                            </div>  
                          </>
                        )

                    }  
                </div>
                <div className="right-content-part">
                      {
                        user ?
                        <div className="logge-nav">
                            <button className="btn" onClick={() => setShowSideNavigation(!showSideNavigation)}>
                              <FaBars />
                            </button>
                        </div>
                        :
                        <div className="log-reg-links">
                          <Link to="/login" className="btn-photocut">SIGN IN</Link>
                          <Link to="/register" className="btn-photocut mg-navleft">SIGN UP</Link>
                        </div>
                      }
                  </div>
            </div> 
            {
                showSideNavigation && (
                  <div className="side-full-navigation">
            
                  <div className="xClose1 text-center">
                      <button className="btn" onClick={() => setShowSideNavigation(!showSideNavigation)}>
                        <FaStop />
                      </button>
                  </div>
                  <div className="nav-items-show">
                    <ul className="list-linker">
                      <li><Link to="/" className="item-link-nav">Home</Link></li>
                      <li><Link to="/create-portfolio" className="item-link-nav">Create Portfolio</Link></li>
                      <li><Link to="/portfolios" className="item-link-nav">My Portfolios</Link></li>
                      <li><Link to="/content-creators" className="item-link-nav">Content Creators</Link></li>
                      <li><Link to="/settings" className="item-link-nav">Settings</Link></li>                
                      <li> 
                        <button className="btn btn-logoff mgtop10" onClick={onLogOut}>
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
              </div> 
                )
              }
                
          </div>
      </div>
    </div>
  )
}

export default TopNavigation