import React from 'react'
import FooterHolder from '../Components/FooterHolder'
import TopNavigation from '../Components/TopNavigation'

function TermsOfUse() {
  return (
    <div className="stream-container">
      <TopNavigation />

    <div className="content-display">
    <p>**PHOTOCUTT Terms of Use**</p>
<p>Welcome to PHOTOCUTT! By accessing or using our website and services, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using PHOTOCUTT.</p>
<p>1. **Acceptance of Terms:** By using PHOTOCUTT, you agree to be bound by these Terms of Use, our Privacy Policy, and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing our services.</p>
<p>2. **User Conduct:** Users of PHOTOCUTT are expected to conduct themselves in a responsible and respectful manner. You agree not to upload, post, or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, or otherwise objectionable. This includes, but is not limited to, explicit content that is not suitable for public viewing.</p>
<p>3. **Content Standards:** You are solely responsible for the content you upload or share on PHOTOCUTT. We reserve the right to remove any content that violates these Terms of Use or is deemed inappropriate at our discretion. Additionally, users who violate these terms may have their accounts suspended or terminated.</p>
<p>4. **Reporting Violations:** If you encounter content on PHOTOCUTT that violates these Terms of Use, please report it to us immediately. We take reports of misconduct seriously and will investigate and take appropriate action, which may include removing the offending content and suspending or terminating the account of the user responsible.</p>
<p>5. **Account Termination:** Users who are found guilty of violating these Terms of Use, including uploading explicit content on a public status, may have their accounts suspended or terminated. In such cases, the user's account and all associated albums may be displayed publicly as a warning to others.</p>
<p>6. **Intellectual Property:** By uploading content to PHOTOCUTT, you retain ownership of your intellectual property rights. However, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, adapt, publish, translate, distribute, and display your content for the purposes of operating and promoting PHOTOCUTT.</p>
<p>7. **Limitation of Liability:** PHOTOCUTT and its affiliates shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our services, including but not limited to loss of data, loss of revenue, loss of profits, or loss of business opportunities.</p>
<p>8. **Changes to Terms:** We reserve the right to modify or update these Terms of Use at any time without prior notice. It is your responsibility to review these terms periodically for changes. Your continued use of PHOTOCUTT after any modifications indicates your acceptance of the updated terms.</p>
<p>9. **Governing Law:** These Terms of Use shall be governed by and construed in accordance with the laws of South Africa. Any dispute arising out of or relating to these terms shall be subject to the exclusive jurisdiction of the courts in [insert jurisdiction].</p>
<p>10. **Contact Us:** If you have any questions or concerns about these Terms of Use, please contact us at tsdevcut@gmail.com.</p>
<p>By using PHOTOCUTT, you acknowledge that you have read, understood, and agree to abide by these Terms of Use. Thank you for being a part of our community!</p>
      </div>
      <div className="below-bottom">
          <FooterHolder />
      </div>
  </div>
  )
}

export default TermsOfUse