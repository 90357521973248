import React, { useEffect, useRef, useState } from 'react'
import TopNavigation from '../Components/TopNavigation';
import FooterHolder from '../Components/FooterHolder';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as CONSTANTS from "./../CONSTANTS";
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaBan, FaEnvelope, FaFacebook, FaInstagram, FaTrash, FaTwitter, FaUpload, FaVoicemail } from 'react-icons/fa';
import { QRCodeCanvas } from "qrcode.react";
import { toPng } from "html-to-image";
import PortfolioGallery from '../Components/PortfolioGallery';
import faceback1 from "./../assets/faceback_1.png";
import faceback2 from "./../assets/faceback_2.png";
import faceback3 from "./../assets/faceback_3.png";
import faceback4 from "./../assets/faceback_4.png";
import faceback5 from "./../assets/faceback_5.png";

import { motion } from "framer-motion";
import PorfolioModalVideos from '../Components/modals/PorfolioModalVideos';
import PortfolioVideoList from '../Components/PortfolioVideoList';
import PorfolioModalDelete from '../Components/modals/PorfolioModalDelete';
import { Helmet } from 'react-helmet';

const gridContainerInfoVariants = {
  hidden:{ opacity: 0},
  show: {
    opacity: 1,
    transition:{
      staggerChildren:0.25,
    },
  },
}

const gridSquareInfoVariants = {
  hidden: { opacity: 0}, show: {opacity: 1}
}

function PortfolioSingle() {
  const [processing, setProcessing]                                                  = useState(false);
  const {user}                                                                       = useSelector((state) => state.auth);

  const params                                                                       = useParams();
    
  const [currentPortfolio, setCurrentPortfolio]                                      = useState(null);
  const [currentUser, setCurrentUser]                                                = useState();

  ////////////
  //Porfolio QR Code background
  const imagesPorfolioBackList = [faceback1, faceback2, faceback3, faceback4, faceback5];
  // Use the selected image
  const imageryPortfolio = imagesPorfolioBackList[Math.floor(Math.random() * imagesPorfolioBackList.length)];
  
  /////////////////////////////////////////
  //Video portfolio fields
  const [porfolioVideoEditingModal, setPorfolioVideoEditingModal]                      = useState(false);
  const [showPorfolioDeleteModal, setShowPorfolioDeleteModal]                      = useState(false);
  /////////////////////////////////////////
  const [messageResponse, setMessageResponse]                      = useState([]);
  const imageDataRef                                               = useRef();
  const [portfolioList, setPorfolioList]                           = useState([]);
  const [portUpdate, setPortUpdate]                                = useState(0);

  const [previewURL, setPreviewURL]                                = useState(null); // State for the preview URL
  const [selectedBannerFile, setSelectedBannerFile]                = useState();
  const [saveBanner, setSaveBanner]                                = useState(false);
  const fileBannerRef                                              = useRef()

  const handleButtonClick = () => {
    fileBannerRef.current.click(); //programmatical trigger
  }

  const handleBannerChange = (event) => {
    const bannerFile = event.target.files[0];
    setSelectedBannerFile(bannerFile);
    setSaveBanner(true);
    setPreviewURL(URL.createObjectURL(bannerFile)); // Set preview URL
  }

  const canvasRef                                                                            = useRef(null);
      
  const generateImageWithStyles = () => {
        var numberRand = Math.floor(Math.random() * 100);
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
    
        // Set canvas size
        canvas.width = 300;
        canvas.height = 300;
    
        // Draw background
        context.fillStyle = "#1a1d1c"; // Background color
        context.fillRect(0, 0, canvas.width, canvas.height);
    
        // Add custom title text with styles
        context.font = "22px 'Allura', cursive";
        context.fillStyle = "#1a8a6d"; // Title color
        context.textAlign = "center";
        //maybe use name
        context.fillText(currentUser.name + " " + currentUser.surname, canvas.width / 2, 40);
    
        // Generate QR code and draw it on the canvas
        const qrCanvas = canvasRef.current.querySelector("canvas");
        if (qrCanvas) {
          context.drawImage(qrCanvas, canvas.width / 2 - 75, 60, 150, 150);
        }
    
        // Add footer text
        context.font = "11px Arial";
        context.fillStyle = "#ced3d1";
        context.fillText("View profile on Photocutt", canvas.width / 2, 240);
    
        // Download the image
        const link = document.createElement("a");
        link.download = "PHOTCUTT_QRCODE_" + numberRand + ".png";
        link.href = canvas.toDataURL("image/png");
        link.click();
  };


  useEffect(() => {     
    collectCurrentPortfolio();
  }, []);

  useEffect(() => {
      if(currentPortfolio){
        collectListOfPictures();
        collectCurrentDetailsUser();
      }
  }, [currentPortfolio, portUpdate])
    
  const collectCurrentPortfolio = async () => {

        try{  
          const res = await axios.get(CONSTANTS.API_URL +"portfolios/current/index/" + params.id);
          setCurrentPortfolio(res.data);
         
        }catch (err) {
            console.log(err);        
        }   
  }

  const collectCurrentDetailsUser = async () => {
    try{
      const res = await axios.get(CONSTANTS.API_URL +"users/creator/single/v1/" + currentPortfolio.userid);
      setCurrentUser(res.data);
      //console.log(res.data);
      //console.log("Market place");
    }catch(err){
      console.log(err);
    }
  }

  const addingMaxCheckImages = (event) => {
      event.preventDefault();
      const selectedFiles = imageDataRef.current.files;
      
      const uploadLimit = currentPortfolio.picCountMax - (portfolioList.length);
      if (selectedFiles.length > uploadLimit) {
          // Show error toast
          toast.error("Sorry, you can only upload "+ uploadLimit +" images.");
          // Clear file input
          imageDataRef.current.value = null;
          return;
      }
  };

  //////////////////////////////////////////////////////
  async function HandleAddingPortfolioPictures(e) {
      e.preventDefault();  
      setProcessing(true);   
        
          const picLength = imageDataRef.current.files.length;
      
          for (let i = 0; i < picLength; i++) {
              const file = imageDataRef.current.files[i];
            
              try {

                const formData = new FormData();
                  formData.append("portfoliourl", file);
                  formData.append("userid", user._id);
                  formData.append("portfolioCode", currentPortfolio.portfolioCode);
                  
                  const response = await axios.post(CONSTANTS.API_URL+"pictures/portfolios/upload" ,formData, {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                          token: "Bearer "+ user.accessToken
                      }
                  });
                
                  if(response.status == 200){
                    var number = i + 1;
                    setMessageResponse(i);
                  }
                } catch (error) {
                  console.error('Error uploading image:', error);
                }
          }

          setProcessing(false);  
      
          //setPictureList([]);
          setPortUpdate(prev => prev + 1);
          // Reset the input field after submission
          if(imageDataRef.current) {
            imageDataRef.current.value = ''; // Resetting the input value
          }
  }

  const collectListOfPictures = async () => {
    try{

      const response = await axios.get(CONSTANTS.API_URL+"pictures/portfolios/list/v1/" +currentPortfolio.portfolioCode);
      setPorfolioList(response.data);
    }catch(err){
      console.log(err);
    }
  }

  const handleDeleteThisPicture = async (portDelete) => {
    setProcessing(true); 
      try{
        
        const deleteObject = {
            "cloudId" : portDelete.cloudinaryid,
            "pictureId" : portDelete._id
        }

        const res = await axios.put(CONSTANTS.API_URL +"pictures/portfolio/removal/", deleteObject, {
              headers: {
                  token: "Bearer "+ user.accessToken
              }
          });

          toast.success("Successfully deleted this image.");
        setPortUpdate(prev => prev + 1);
        setProcessing(false);
      }catch(err){
        console.log(err);
        setProcessing(false); 
        toast.error("Something went wrong, please try again later.");
      }
  }

  const handleUploadBanner = async () => {
      try{

        setProcessing(true);  

        const formData = new FormData();
          formData.append("porfoliobannerurl", selectedBannerFile);
          formData.append("userid", user._id);
          formData.append("cloudid", currentPortfolio?.cloudinaryid ?? "");
          formData.append("portfolioid", currentPortfolio._id);
          
          const response = await axios.post(CONSTANTS.API_URL+"pictures/banner/portfolios/upload/v1" ,formData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  token: "Bearer "+ user.accessToken
              }
          });
          
        setCurrentPortfolio(response.data);
        setProcessing(false);  
        toast.success("Banner has been updated.")

      }catch(err){
        setProcessing(false);  
        console.log(err);
        toast.error("Something went wrong, please try again later.");
      }
  }


  return (
    <div className="stream-container">
         {
            currentPortfolio && ( 
              <Helmet>
                      <title>Porfolio - {currentPortfolio.title}</title>
                      <meta name="description" content={currentPortfolio.title + " - mesmerizing Photocutt portfolio on display"} />
              </Helmet>)
          } 

        
         <TopNavigation />
        <div className="initial-title">
           Portfolio
        </div>
        <div className="content-display">
            
            {
              ((user != null) && (user != undefined) && (currentPortfolio && (user._id === currentPortfolio.userid)) ) && (
                <div className="row row-authority">
                   <div className="col-md-4">
                      <div className="upload-section">   
                           <form onSubmit={HandleAddingPortfolioPictures}>
                                <div className="form-group">
                                    <div className="lbldesc">Portfolio Pictures* :</div>
                                    <input 
                                      type="file" 
                                      className="form-control wide100" 
                                      ref={imageDataRef} 
                                      accept="image/*"
                                      onChange={addingMaxCheckImages}
                                      multiple required/>
                                </div>
                            
                                <div className="form-group">
                                    <button type="submit" className="btn btn-photocut" disabled={processing}>UPLOAD</button>                             
                                </div>
                            </form>                     
                      </div>
                   </div>
                   <div className="col-md-3">
                      <div className="status-section">                                                
                          {currentPortfolio && (
                            <>
                              {currentPortfolio.visible ? "Visible" : "Private"}
                            </>
                          )
                          }   
                      </div>
                      <div className="count-section">    
                          {currentPortfolio && (<h3>{currentPortfolio.picCountMax} Max</h3>)}   
                          <p>Current: {portfolioList.length} </p>                 
                      </div>
                   </div>
                   <div className="col-md-2">
                       <div className="respond-section">    
                          {messageResponse && (
                            <>
                             { messageResponse + 1} Total updates.
                            </>
                           )}                    
                      </div>    
                   </div>
                   <div className="col-md-2">
                      <div className="list-banner-update text-center">
                        <button className="btn btn-banner" onClick={handleButtonClick}>
                          <FaUpload /> <span>Banner</span>
                        </button>
                        
                        <div className="mgtop10">
                            <input 
                                type="file"
                                className="display-hide"
                                accept="image/*"
                                ref={fileBannerRef}
                                onChange={handleBannerChange}
                                />

                              {
                                saveBanner && 
                                (<div className="save-area">
                                 {previewURL && <img src={previewURL} alt="Preview" className="circle-banner" />}     
                                                              
                                    <button className="btn btn-banner" onClick={() => handleUploadBanner()}>
                                      <FaUpload /> <span>Save</span>
                                    </button>
                                  </div>)
                              }
                        </div>
                      </div>
                   </div>
                   <div className="col-md-2">
                      <p>To add or remove videos</p>
                      <button className="btn btn-photocut" onClick={() => setPorfolioVideoEditingModal(true)}>Video Edits</button>
                   </div>
                   <div className="col-md-2">
                      <p>Delete Portfolio</p>
                      <button className="btn btn-photocut" onClick={() => setShowPorfolioDeleteModal(true)}>Delete</button>
                   </div>
                </div>
              )
            }

            <div className="content-image-list title-listing">
                {
                    currentPortfolio && (<h3 className="title-fade-out" >{currentPortfolio.title}</h3>)
                } 

               <div className="glitching-banner">
                  {
                    currentPortfolio && (<h3  className="glitching-title" data-text={currentPortfolio.title} >{currentPortfolio.title}</h3>)
                  }   
               </div>
            </div>               
        </div>
        <div className="contact-details-one">
           <div className="container">
            {
              currentUser && (
                <motion.div 
                className="grid-boxes-col-3"
                variants={gridContainerInfoVariants}
                initial='hidden'
                animate='show'
                >
                  {/*Fade in */}
                  <motion.div 
                    variants={gridSquareInfoVariants}
                    className="grid-item-bx">
                     <div className="prof-user">
                        <motion.div 
                            initial={{ opacity: 0, y:100}}
                            animate={{ opacity: 1, y:0}}
                            transition={{duration:1, ease: "easeOut", delay:0.2}}
                            className=" name-prof">
                            {currentUser.name}
                          </motion.div>
                          <motion.div
                            initial={{ opacity: 0, y: -100}}
                            animate={{ opacity: 1, y: 0}}
                            transition={{duration:1, ease: "easeOut", delay:0.4}} 
                            className=" surname-prof">
                              {currentUser.surname}</motion.div>
                     </div>

                    </motion.div>
                  
                  <motion.div 
                    variants={gridSquareInfoVariants}
                    className="grid-item-bx">
                      <motion.div
                        className="info-image-profile"
                        style={{
                          backgroundImage:`url(${currentUser.profilePic})`
                        }}
                        animate={{
                          scale: [1, 2, 2, 1],
                          rotate:[0, 90, 90, 0],
                          borderRadius: ["10%", "10%", "50%", "10%"]
                        }}
                        transition={{ duration: 5, ease: "easeInOut", repeat: Infinity, repeatDelay:1}}
                        >
                      </motion.div>
                    </motion.div>
                  
                  <motion.div 
                    variants={gridSquareInfoVariants}
                    className="grid-item-bx">
                      <div className="prof-user-email">
                           <div className="eicon">
                           <FaEnvelope />
                           </div>
                           {
                            currentUser.email.length > 0 ?
                            <motion.div 
                              className="link-action"
                              whileHover={{
                                scale:1.1,
                                color: "#1a1d1c"
                              }}
                              >
                              <Link to={"mailto:" + currentUser.email}>
                                 {currentUser.email}
                              </Link>
                            </motion.div>
                            :
                            <div className="restriction">
                              <FaBan />
                            </div>
                           }
                      </div>
                    </motion.div>
                  
                  <motion.div 
                    variants={gridSquareInfoVariants}
                    className="grid-item-bx">
                      <div className="prof-social">
                           <div className="eicon fb">
                           <FaFacebook />
                           </div>
                           {
                            currentUser.facebook.length > 0 ?
                            <motion.div 
                              className="link-action"
                              whileHover={{
                                opacity: 0.5,
                                color: "#155197"
                              }}
                              >
                              <Link to={"https://facebook.com/" + currentUser.facebook} target="_blank">
                                 @{currentUser.facebook}
                              </Link>
                            </motion.div>
                            :
                            <div className="restriction">
                              <FaBan />
                            </div>
                           }
                      </div>
                    </motion.div>
                  
                  <motion.div 
                    variants={gridSquareInfoVariants}
                    className="grid-item-bx">
                      <div className="prof-social">
                           <div className="eicon inst">
                           <FaInstagram />
                           </div>
                           {
                            currentUser.instagram.length > 0 ?
                            <motion.div 
                              className="link-action"
                              whileHover={{
                                opacity: 0.5,
                                color: "#d62976"
                              }}
                              >
                              <Link to={"https://instagram.com/" + currentUser.instagram} target="_blank">
                                 @{currentUser.instagram}
                              </Link>
                            </motion.div>
                            :
                            <div className="restriction">
                              <FaBan />
                            </div>
                           }
                      </div>
                    </motion.div>
                  
                  <motion.div 
                    variants={gridSquareInfoVariants}
                    className="grid-item-bx">
                       <div className="prof-social">
                           <div className="eicon twit">
                           <FaTwitter />
                           </div>
                           {
                            currentUser.twitter.length > 0 ?
                            <motion.div 
                              className="link-action"
                              whileHover={{
                                opacity: 0.5,
                                color: "#1DA1F2"
                              }}
                              >
                              <Link to={"https://twitter.com/" + currentUser.twitter} target="_blank">
                                 @{currentUser.twitter}
                              </Link>
                            </motion.div>
                            :
                            <div className="restriction">
                              <FaBan />
                            </div>
                           }
                      </div>
                    </motion.div>
                </motion.div>
              )
            }            
           </div>
        </div> 
        <div className="port-sct-qrcode back-port" style={{ 
              backgroundImage: `url("${imageryPortfolio}")` 
            }}>
             <div class="port-train">
                   <div ref={canvasRef} style={{ display: "none" }}>
                        <QRCodeCanvas 
                            value={window.location.href} size={150}
                            fgColor="#1a1d1c" // Background color of the QR code
                            bgColor="#1a8a6d" // Foreground color of the QR code
                            />
                    </div>
                
                <div className="text-center">
                    <h2>Profile QR Code</h2>
                    <p>Download a qrcode</p>
                    <button
                            onClick={generateImageWithStyles}
                            className="btn-photocut"
                            style={{
                              marginTop: "20px",
                            }}
                        >
                          Download Image
                    </button>
                </div>
             </div>
        </div>
        <div className="text-center sect-space1">
          {
            currentUser && (
              <Link to={"/this-creator/" + currentUser._id} className="btn-photocut">
                    View Portfolios
              </Link>
            )
          }           
        </div>
        <div className="view-imagery-list">
          {
            portfolioList && 
            portfolioList.length > 0 && ( 
              <PortfolioGallery pictures={portfolioList} portTitle={currentPortfolio.title} />
            )
          } 
        </div>
        <div className="text-center sect-space1">
          {
            currentUser && (
              <Link to={"/this-creator/" + currentUser._id} className="btn-photocut">
                    View Portfolios
              </Link>
            )
          }           
        </div>
        <div className="modal--imagery-list">
          {

            /*
            Add this to a modal
            portfolioList && 
            portfolioList.length > 0 && (
              <div className="row">
                {
                  portfolioList.map((port, index) => {
                    return <div className="col-md-1 picb" key={index}>
                              
                                {                                         
                                  (user && (user._id === currentPortfolio.userid)) && (
                                    <div className="position-delete">
                                      <button className="btn btn-over btn-trasher" onClick={() => handleDeleteThisPicture(port)}>
                                        <FaTrash />
                                      </button>
                                    </div> 
                                  )
                                }
                                
                                <img src={port.picurl} 
                                  alt={"PHOTO CUTTS " + currentPortfolio.title}
                                  loading="lazy" 
                                  className="img-special mgbot20" />
                          </div>
                  })
                }
              </div>
            )
            */
          }
        </div>

      {
        currentPortfolio &&
        currentPortfolio !== null &&
        currentPortfolio.videoItems.length > 0 && (
          <div className="listing-videos">
           <PortfolioVideoList videoList={currentPortfolio.videoItems} />
          </div>
        )
      }
      {
        porfolioVideoEditingModal && (
          <PorfolioModalVideos 
            user={user} 
            currentPortfolio={currentPortfolio} 
            setPorfolioVideoEditingModal={setPorfolioVideoEditingModal} 
            setProcessing={setProcessing}
            processing={processing}
            setCurrentPortfolio={setCurrentPortfolio}
            />
        )
      }
      {
        showPorfolioDeleteModal && (
          <PorfolioModalDelete
            user={user}
            currentPortfolio={currentPortfolio}
            setShowPorfolioDeleteModal={setShowPorfolioDeleteModal}
            setProcessing={setProcessing}
            />
        )
      }
      
        {
            processing && (
                <div className="img-source">
                  <img src={CONSTANTS.BLUE_PROCESSING} className="proc" />
                </div>
            )
        }
        <div className="below-bottom">
             <FooterHolder />
        </div>
    </div>
  )
}

export default PortfolioSingle