import React, { useEffect, useState } from 'react';
import TopNavigation from '../Components/TopNavigation'
import FooterHolder from '../Components/FooterHolder'
import { useSelector } from 'react-redux';
import HomeNoAuth from '../Components/HomeNoAuth';
import HomeShortList from '../Components/HomeShortList';
import FollowList from '../Components/sidewidgets/FollowList';
import HomeStartCreators from '../Components/HomeStartCreators';
import { FaUser } from 'react-icons/fa';
import * as CONSTANTS from "./../CONSTANTS";
import axios from 'axios';
import { Link } from 'react-router-dom';

function Home() {

  const {user}                                                  = useSelector((state) => state.auth);
  //Temporary display of creators
  const [listCreators, setListCreators]                         = useState([]);

  useEffect(() => {
      collectionOfCreators();
  },[])

  const collectionOfCreators = async () => {
      try{
        
          const response = await axios.get(CONSTANTS.API_URL + "users/content/creators/v1");
      
          setListCreators(response.data);
      }catch(err){
          console.log(err)
      }
  }

  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;

  };

  return (
    <div className="stream-container">
      <div className="upper-top">
          <TopNavigation />
      </div>
      <div className="content-main">
         <HomeStartCreators />
         <div className="area-layer-phase">
            <div className="row">
              
                {
                    listCreators && 
                    listCreators.length > 0 && (
                        listCreators.map((listing, index) => {
                            return <div className="col-md-2" key={index}>
                                <div className="cicile-card">
                                    <div className="title-creator">
                                        <Link to={"/this-creator/" + listing._id}
                                            className="prof-link">
                                           {listing.name} {listing.surname}
                                        </Link>
                                    </div>                                    
                                    <div className="profile-item-lst" style={{ backgroundColor: getRandomColor() }}>
                                        <Link to={"/this-creator/" + listing._id}
                                            className="prof-link">
                                            {
                                                listing.profilePic ? (
                                                    <img 
                                                        src={listing.profilePic} 
                                                        alt={`${listing.name} ${listing.surname}`} 
                                                        className="fit-profiles"
                                                    />
                                                ) : (
                                                    <FaUser />
                                                )
                                            }
                                        </Link>
                                    </div>                                    
                                </div>
                            </div>
                        })
                    )
                }
            </div>
          </div>
      </div>
      <div className="below-bottom">
        <FooterHolder />
      </div>
    </div>
  )
}

export default Home
