import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './topNav.css';

function FooterHolder() {
  const d = new Date();

  const [loading, setLoading]                 = useState(true);


  
  useEffect(() => {
    // Simulate page load or API call
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500); // Adjust duration as needed

    return () => clearTimeout(timer);
  }, []);

  
  return (
    <div className="footwork">

      <div className={`pre-page-loader ${loading ? '' : 'hidden'}`}>
        <div className="loading-data">
          <h3 className="ft-meta1">PHOTOCUTT</h3>      
           <div class="lds-ripple">
              <div></div>
              <div></div>
           </div>
        </div>
      </div>
      <div className="advert-list-slider">
        <div className="logos">
            <div className="logo_items">
                <Link to={"https://loved.tsdevcut.co.za"}  target="_blank">
                  <img src="https://loved.tsdevcut.co.za/wp-content/themes/lovedrip/images/logo.png" className="advert-img" alt="Lovedripp Photocutt"/>
                </Link>
                <Link to={"https://bantus.tsdevcut.co.za/"} target="_blank">
                  <img src="https://www.tsdevcut.co.za/wp-content/uploads/2024/10/King-Moshoeshoe.png" className="advert-img" alt="Royal Bantus Photocutt"/>
                </Link>
                <Link to={"https://votel.tsdevcut.co.za/"} target="_blank">
                  <img src="https://votel.tsdevcut.co.za/static/media/logo.e55ba2466959f93dd039.png" className="advert-img" alt="Votel Photocutt"/>
                </Link>
                <Link to={"https://booka.tsdevcut.co.za/"} target="_blank">
                  <img src="https://booka.tsdevcut.co.za/wp-content/themes/booka/images/booka_logo-2.png" className="advert-img" alt="Booka Photocutt"/>
                </Link>
                <Link to={"https://loved.tsdevcut.co.za"}  target="_blank">
                  <img src="https://loved.tsdevcut.co.za/wp-content/themes/lovedrip/images/logo.png" className="advert-img" alt="Lovedripp Photocutt"/>
                </Link>
                <Link to={"https://bantus.tsdevcut.co.za/"} target="_blank">
                  <img src="https://www.tsdevcut.co.za/wp-content/uploads/2024/10/King-Moshoeshoe.png" className="advert-img" alt="Royal Bantus Photocutt"/>
                </Link>
                <Link to={"https://votel.tsdevcut.co.za/"} target="_blank">
                  <img src="https://votel.tsdevcut.co.za/static/media/logo.e55ba2466959f93dd039.png" className="advert-img" alt="Votel Photocutt"/>
                </Link>
                <Link to={"https://booka.tsdevcut.co.za/"} target="_blank">
                  <img src="https://booka.tsdevcut.co.za/wp-content/themes/booka/images/booka_logo-2.png" className="advert-img" alt="Booka Photocutt"/>
                </Link>
              </div>
        </div>
      </div>
      <div className="foot-sub">
        <ul className="copy-list">
          <li><Link to="/">HOME</Link></li>
          <li><Link to="/about">ABOUT</Link></li>
          <li><Link to="/terms-of-use">TERMS OF USE</Link></li>
          <li><Link to="/privacy">PRIVACY</Link></li>
        </ul>

        <div className="copy-text">
        <span> &copy;</span>
        <span> {d.getFullYear()} </span>
        <Link to="https://www.tsdevcut.co.za/" target="_blank">TSDEVCUT</Link>
        </div>
        </div>
    </div>
  )
}

export default FooterHolder