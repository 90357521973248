
export const API_URL = "https://photocutt-10d49844bab9.herokuapp.com/api/";
export const SESSION_COOKIE = "userPhotoListAuthenticatioin";

/*
export const API_URL = "http://localhost:22237/api/";
export const SESSION_COOKIE = "userLocalPhontoShrededHymbooks";
*/

export const SMALL_PROCESSING = "https://tsdevcut.co.za/factory/proc_sm.gif";
export const BLUE_PROCESSING = "https://tsdevcut.co.za/factory/process.gif";
export const IMAGE_PROFILE = "https://tsdevcut.co.za/factory/u_profile.png";
export const PREVIEW_IMAGE = "https://tsdevcut.co.za/factory/profile.png";
